.audioplayer {
    height: 2.5em; /* 40 */
    color: #fff;
    text-shadow: 1px 1px 0 #000;
    border: 1px solid #222;
    position: relative;
    z-index: 1;
    background: #292b2c;
    border-radius: 2px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    max-width: 500px;
    display: flex;
}

.audioplayer-mini {
    width: 7em;
    margin: 0 auto;
}

/*.audioplayer-time-divider {*/
/*    width: 6px;*/
/*}*/

@media screen and (max-width: 329px) {
    .audioplayer-mini {
        width: 4em;
    }
}

.audioplayer > div {
    position: relative;
}

.audioplayer-playpause {
    width: 2.5em; /* 40 */
    height: 100%;
    text-align: left;
    text-indent: -9999px;
    cursor: pointer;
    z-index: 2;
    top: 0;
}

.audioplayer:not(.audioplayer-mini) .audioplayer-playpause {
    border-right: 1px solid #555;
    border-right-color: rgba(255, 255, 255, .1);
}

.audioplayer-mini .audioplayer-playpause {
    width: 100%;
}

.audioplayer-playpause:hover,
.audioplayer-playpause:focus {
    background-color: #222;
}

.audioplayer-playpause a {
    display: block;
}

.audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
    width: 0;
    height: 0;
    border: 0.5em solid transparent; /* 8 */
    border-right: none;
    border-left-color: #fff;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.5em 0 0 -0.25em; /* 8 4 */
}

.audioplayer-playing .audioplayer-playpause a {
    width: 0.75em; /* 12 */
    height: 0.75em; /* 12 */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.375em 0 0 -0.375em; /* 6 */
}

.audioplayer-playing .audioplayer-playpause a:before, .audioplayer-playing .audioplayer-playpause a:after {
    width: 40%;
    height: 130%;
    background-color: #fff;
    content: '';
    position: absolute;
    top: -2px;
}

.audioplayer-playing .audioplayer-playpause a:before {
    left: 0;
}

.audioplayer-playing .audioplayer-playpause a:after {
    right: 0;
}

.audioplayer-time {
    width: 4.375em; /* 70 */
    height: 100%;
    line-height: 2.375em; /* 38 */
    text-align: center;
    z-index: 2;
    top: 0;
}

@media screen and (max-width: 329px) {
    .audioplayer-mini > .audioplayer-time {
        display: none;
    }
}

.audioplayer-time-current {
    border-left: 1px solid #111;
    border-left-color: rgba(0, 0, 0, .25);
}

.audioplayer-time-duration {
    border-right: 1px solid #555;
    border-right-color: rgba(255, 255, 255, .1);
    width: 4.4em;
}

.audioplayer-novolume .audioplayer-time-duration {
    border-right: 0;
    right: 0;
}

.audioplayer-bar {
    height: 0.875em; /* 14 */
    background-color: #222;
    cursor: pointer;
    z-index: 1;
    top: 50%;
    flex-grow: 1;
    margin-top: -0.438em; /* 7 */
}

.audioplayer-novolume .audioplayer-bar {
    right: 4.375em; /* 70 */
}

.audioplayer-bar div {
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.audioplayer-bar-loaded {
    background-color: #333;
    z-index: 1;
}

.audioplayer-bar-played {
    background: #ccc;
    z-index: 2;
}

.audioplayer-volume {
    width: 2.5em; /* 40 */
    height: 100%;
    border-left: 1px solid #111;
    border-left-color: rgba(0, 0, 0, .25);
    border-right: 1px solid #555;
    border-right-color: rgba(255, 255, 255, .1);
    text-align: left;
    text-indent: -9999px;
    cursor: pointer;
    z-index: 2;
    top: 0;
}

.audioplayer-volume:hover,
.audioplayer-volume:focus {
    background-color: #222;
}

.audioplayer-volume-button {
    width: 100%;
    height: 100%;
}

.audioplayer-volume-button a {
    width: 0.313em; /* 5 */
    height: 0.375em; /* 6 */
    background-color: #fff;
    display: block;
    position: relative;
    z-index: 1;
    top: 40%;
    left: 35%;
}

.audioplayer-volume-button a:before,
.audioplayer-volume-button a:after {
    content: '';
    position: absolute;
}

.audioplayer-volume-button a:before {
    width: 0;
    height: 0;
    border: 0.5em solid transparent; /* 8 */
    border-left: none;
    border-right-color: #fff;
    z-index: 2;
    top: 50%;
    right: -0.25em;
    margin-top: -0.5em; /* 8 */
}

.audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after {
    /* "volume" icon by Nicolas Gallagher, http://nicolasgallagher.com/pure-css-gui-icons */
    width: 0.313em; /* 5 */
    height: 0.313em; /* 5 */
    border: 0.25em double #fff; /* 4 */
    border-width: 0.25em 0.25em 0 0; /* 4 */
    left: 0.663em; /* 9 */
    top: 0; /* 1 */
    -webkit-border-radius: 0 0.938em 0 0; /* 15 */
    -moz-border-radius: 0 0.938em 0 0; /* 15 */
    border-radius: 0 0.938em 0 0; /* 15 */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.audioplayer-volume-adjust {
    height: 6.25em; /* 100 */
    cursor: default;
    position: absolute;
    left: 0;
    right: -1px;
    top: -9999px;
    background: #292b2c;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-topright: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.audioplayer-volume-adjust {
    height: 6.25em; /* 100 */
    cursor: default;
    position: absolute;
    left: 0;
    right: -1px;
    top: -9999px;
    background: #292b2c;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-topright: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.audioplayer-volume:not(:hover) .audioplayer-volume-adjust {
    opacity: 0;
}

.audioplayer-volume:hover .audioplayer-volume-adjust {
    top: auto;
    bottom: 100%;
}

.audioplayer-volume-adjust > div {
    width: 40%;
    height: 80%;
    background-color: #222;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: 30% auto 0;
}

.audioplayer-volume-adjust div div {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ccc;
}

.audioplayer-novolume .audioplayer-volume {
    display: none;
}

.audioplayer-play,
.audioplayer-pause,
.audioplayer-volume a {
    -webkit-filter: drop-shadow(1px 1px 0 #000);
    -moz-filter: drop-shadow(1px 1px 0 #000);
    -ms-filter: drop-shadow(1px 1px 0 #000);
    -o-filter: drop-shadow(1px 1px 0 #000);
    filter: drop-shadow(1px 1px 0 #000);
}

.audioplayer-bar,
.audioplayer-bar div,
.audioplayer-volume-adjust div {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.audioplayer-bar,
.audioplayer-volume-adjust > div {
    -webkit-box-shadow: -1px -1px 0 rgba(0, 0, 0, .5), 1px 1px 0 rgba(255, 255, 255, .1);
    -moz-box-shadow: -1px -1px 0 rgba(0, 0, 0, .5), 1px 1px 0 rgba(255, 255, 255, .1);
    box-shadow: -1px -1px 0 rgba(0, 0, 0, .5), 1px 1px 0 rgba(255, 255, 255, .1);
}

.audioplayer-volume-adjust div div,
.audioplayer-bar-played {
    -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, .5);
    -moz-box-shadow: inset 0 0 5px rgba(255, 255, 255, .5);
    box-shadow: inset 0 0 5px rgba(255, 255, 255, .5);
}

.audioplayer-volume-adjust {
    -webkit-box-shadow: -2px -2px 2px rgba(0, 0, 0, .15), 2px -2px 2px rgba(0, 0, 0, .15);
    -moz-box-shadow: -2px -2px 2px rgba(0, 0, 0, .15), 2px -2px 2px rgba(0, 0, 0, .15);
    box-shadow: -2px -2px 2px rgba(0, 0, 0, .15), 2px -2px 2px rgba(0, 0, 0, .15);
}

.audioplayer *,
.audioplayer *:before,
.audioplayer *:after {
    -webkit-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    -moz-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    -ms-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    -o-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    transition: color .25s ease, background-color .25s ease, opacity .5s ease;
}

.audioplayer-download {
    width: 2.5em; /* 40 */
    height: 100%;
    border-left: 1px solid #111;
    border-left-color: rgba(0, 0, 0, .25);
    cursor: pointer;
    z-index: 2;
    top: 2px;
}

a.audioplayer-download-button {
    color: white;
    text-decoration: none;
    line-height: 33px;
    text-align: center;
    width: 100%;
}

.navbar-header .audioplayer {
    top: 9px;
}

@media (max-width: 768px) {
    .navbar-header .audioplayer {
        top: 3px;
}
}

.audioplayer-mini .audioplayer-playpause, .audioplayer-mini .audioplayer-playing, .audioplayer-mini .audioplayer-pause{
    padding-left: .5rem;
    padding-right: .5rem;
    margin-left: 0.7rem;
    width: 2rem;
}

.audioplayer-space{
    width: 6px;
    height: 100%;
    border-left: 1px solid #111;
    border-left-color: rgba(0,0,0,.25);
    cursor: pointer;
    z-index: 2;
    top: -2%;
}

.audioplayer-ten-seconds-back{
    position: relative;
    padding: .5rem .5rem;
    margin-right: 0;
}

.audioplayer-ten-seconds-back a{
    position: absolute;
    top: 12px;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.5);
}

.ten-seconds-back-mask {
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 999;
    width: 32px;
    height: 32px;
}

.audioplayer-playing .ten-seconds-back-mask {
    display: none;
}

.audioplayer-playing .audioplayer-ten-seconds-back a{
    position: absolute;
    top: 12px;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.75);
}

.audioplayer-playing .audioplayer-ten-seconds-back a:hover {
    color: rgba(255, 255, 255, .9);
    text-decoration: none;
}
